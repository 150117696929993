/* Nav flyUp animation */
@keyframes flyUp {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.navFlyUpAnimation {
  animation: flyUp 0.8s ease-out forwards;
}

/* About fly up on scroll*/
@keyframes revealOnScroll {
  from {
    transform: translateY(20vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.revealOnScroll {
  animation: revealOnScroll 1s ease-out forwards;
}

/* fly in sideways from right*/
@keyframes revealOnScrollFromRightSide {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.revealOnScrollFromRightSide {
  animation: revealOnScrollFromRightSide 1s ease-out forwards;
}

/* fly in sideways from left*/
@keyframes revealOnScrollFromLeftSide {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.revealOnScrollFromLeftSide {
  animation: revealOnScrollFromLeftSide 1s ease-out forwards;
}

/* Hero reveal on load */
@keyframes revealFromBottomToTop {
  from {
    clip-path: inset(100% 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.imageReveal {
  clip-path: inset(100% 0 0 0);
  animation: revealFromBottomToTop 1.3s cubic-bezier(0.94, 0, 1, 1) forwards;
  animation-delay: 0.5s;
}

/* Title fadeIn */
@keyframes fadeInTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInTitle {
  animation: fadeInTitle 1s forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.fadeInTitle1 {
  animation-delay: 0.3s;
}

.fadeInTitle2 {
  animation-delay: 0.6s;
}

.fadeInTitle3 {
  animation-delay: 0.9s;
}

.fadeInParagraph {
  animation-delay: 1.2s;
}

/* Footer fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.infoLeft,
.infoRight {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}

.infoLeft {
  animation-delay: 1s;
}

.infoRight {
  animation-delay: 1s;
}

.hide {
  opacity: 0;
}
