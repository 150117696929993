.styledHeader {
  padding: 0 50px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 11;
  width: 100%;
  height: var(--nav-height);
  background-color: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(10px);
  transition: var(--transition);
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  box-shadow: 0 10px 30px -15px var(--dark-navy);
}

.navContainer {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  color: var(--lightest-slate);
  font-family: var(--font-mono);
  counter-reset: item 0;
  z-index: 12;
}

.navbar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.navbar ol {
  display: flex;
  list-style: none;
}

.navbar ol li {
  counter-increment: item 1;
}

.navbar ol li a {
  text-decoration: none;
  color: var(--light-slate);
  margin-right: 2.5rem;
  font-size: 1rem;
}

.navbar ol li a::before {
  content: '0' counter(item) '.';
  color: var(--green);
  margin-right: 10px;
}

.navbar ol li a:hover {
  color: var(--green);
  transition: 0.3s ease-in-out;
}

.menu {
  display: none;
}

.hamburgerButton .hambox {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 24px;
}

.hamburgerButton .hamboxInner {
  position: absolute;
  top: 50%;
  right: 0px;
  width: var(--hamburger-width);
  height: 2px;
  border-radius: var(--border-radius);
  background-color: var(--green);
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: 0s;
  transform: rotate(0deg);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburgerButton .hamboxInner::before {
  width: 120%;
  top: -10px;
  opacity: 1;
  transition: var(--ham-before);
}

.hamburgerButton .hamboxInner::after {
  width: 80%;
  bottom: -10px;
  transform: rotate(0deg);
  transition: var(--ham-after);
}

.hamburgerButton .hamboxInner::before,
.hamboxInner::after {
  content: '';
  display: block;
  position: absolute;
  left: auto;
  right: 0px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--green);
}

.sidebar nav {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  color: var(--lightest-slate);
  font-family: var(--font-mono);
  text-align: center;
}

.sidebar nav:focus {
  outline: none;
}

.sidebar ol {
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 100%;
}

.sidebar ol li {
  position: relative;
  counter-increment: item 1;
  font-size: clamp(var(--fz-sm), 4vw, var(--fz-lg));
}
.sidebar ol li::before {
  content: '0' counter(item) '.';
  display: block;
  margin-bottom: 5px;
  color: var(--green);
  font-size: var(--fz-sm);
}
.sidebar ol a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
  width: 100%;
  padding: 3px 20px 20px;
}
.sidebar .resumeLink {
  color: var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  border-radius: var(--border-radius);
  font-size: var(--fz-sm);
  font-family: var(--font-mono);
  line-height: 1;
  text-decoration: none;
  transition: var(--transition);
  padding: 18px 50px;
  margin: 10% auto 0px;
  width: max-content;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px)  {
  .styledHeader {
    padding: 0px 25px;
  }
  .navbar {
    display: none;
  }
  .menu {
    display: block;
  }
  .hamburgerButton {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-right: -15px;
    padding: 15px;
    border: 0px;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    cursor: pointer;
    border-radius: 0px;
  }
  .sidebar {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 50px 10px;
    width: min(75vw, 400px);
    height: 100vh;
    outline: 0px;
    background-color: var(--light-navy);
    box-shadow: -10px 0px 30px -15px var(--navy-shadow);
    z-index: 9;
    visibility: hidden;
    transition: var(--transition);
  }
  .sidebarOpen {
    transform: translateX(0);
    visibility: visible;
  }
  .sidebar ol li {
    margin: 0px auto 10px;
  }
}

/* Small devices rotated horizontally */
@media only screen and (max-height:500px) and (orientation: landscape) {
  .styledHeader {
    padding: 0px 25px;
    height: 75px;
  }
  .navbar {
    display: none;
  }
  .menu {
    display: block;
  }
  .hamburgerButton {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-right: -15px;
    padding: 15px;
    border: 0px;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    cursor: pointer;
    border-radius: 0px;

  }
  .sidebar {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 10px 10px;
    width: min(35vw, 400px);
    height: 100vh;
    outline: 0px;
    background-color: var(--light-navy);
    box-shadow: -10px 0px 30px -15px var(--navy-shadow);
    z-index: 9;
    visibility: hidden;
    transition: var(--transition);
  }

  .sidebar ol a {
    padding: 3px 10px 10px;
  }
  .sidebarOpen {
    transform: translateX(0);
    visibility: visible;
  }
  .sidebar ol li {
    margin: 0px auto 10px;
  }
 }
