#App-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  margin: 0px auto;
  width: 100%;
  max-width: 1600px;
  min-height: 100vh;
}
.fillHeight {
  padding: 0px 150px;
}

@media (max-width: 600px) {
  main.fillHeight {
    padding: 0px 25px;
  }
}

@media only screen and (max-height:500px) and (orientation: landscape) {
  main.fillHeight {
    /* padding: 0px 25px; */
  }
 }