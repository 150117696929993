.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: opacity 0.3s ease-out;
  transform: translateY(0);
}

.infoLeft {
  margin-left: 1.3rem;
  position: fixed;
  left: 4rem;
  bottom: 3rem;

}

.infoRight {
  position: fixed;
  right: 2.5rem;
  bottom: 3rem;
}

.infoContact {
display: flex;
  font-size: 0.9rem;
  width: 100%;

}

.infoContact ul {
  text-decoration: none;
  list-style: none;

}

.infoContact li {
  padding-bottom: 0.3rem;
}

.occupation {
  padding-right: 2.5rem;
  color: var(--light-slate);
}

.social {
  position: relative;
  left: -5rem;
}

.social ul {
  list-style-type: none;
}

.social li {
  padding-bottom: 3px;
}

.social a {
  text-decoration: none;
  color: var(--light-slate);
  font-size: 1rem;
}

.social a:hover {
  color: var(--green);
  transition: 0.7s ease-in-out;
}

.social span {
  margin-right: 10px;
  letter-spacing: -0.03rem;
  font-size: 0.9rem;
  color: var(--green);
}

/* Extra extra large devices (large laptops and desktops, until 1366px and lower) */
@media (max-width: 1366px) {
  .infoLeft {
    bottom: 1rem;
  }
  .infoRight {
    bottom: 1rem;
  }
  .social {
    left: 0;
  }
  .social ul {
    display: flex;
  }
  .social a {
    margin-right: 10px;
  }
  .social span {
    margin-right: 5px;
  }
}
/* Extra large devices (large laptops and desktops, until 1200px and lower) */
@media (max-width: 1200px) {
  .infoLeft {
    bottom: 1rem;
  }

  .infoRight {
    bottom: 1rem;
  }

  .social {
    left: 0;
  }
  .social ul {
    display: flex;
  }
  .social a {
    margin-right: 10px;
  }
  .social span {
    margin-right: 5px;
  }
}

/* Medium devices (landscape tablets, until 768px and lower) */
@media (max-width: 768px) {
  .infoLeft {
    margin-left: 0;
    left: 0;
    padding-left: 5%;
  }

  .infoRight {
    display: none;
    right: 0;
  }

  .social {
    left: -36px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .infoRight {
    display: none;
  }
}

@media only screen and (max-height:500px) and (orientation: landscape) { 
  .infoContact  {
    display: none;

  }

}
