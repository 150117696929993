.aboutWrapper {
  padding-bottom: 100px;
  max-width: 1100px;
  margin: 0px auto;
}

.aboutMeHead {
  opacity: 0;
  display: flex;
  margin: 10px 0 40px 0;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
  color: var(--lightest-slate);
}

.aboutMeHead::before {
  counter-increment: section 1;
  content: '0' counter(section) '.';
  margin-right: 10px;
  color: var(--green);
}

.aboutMeHead::after {
  content: '';
  display: block;
  position: relative;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: var(--lightest-navy);
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.aboutMeInfo {
  opacity: 0;
}

.aboutMeInfo p {
  margin-bottom: 20px;
}

.skillsListParagraph {
  opacity: 0;
}

.skillsList {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  margin-top: 1em;
  list-style-type: none;
  opacity: 0;
}

.skillsList li::before {
  content: '▹';
  color: var(--green);
}

.imageWrapper {
  flex-shrink: 0;
  width: 30%;
}

.imageWrapper img {
  height: auto;
  width: 100%;
  object-fit: cover;
  opacity: 0;
}

/* Extra extra large devices (large laptops and desktops, until 1366px and lower) */
@media (max-width: 1366px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .aboutWrapper {
  }
}

/* Medium devices (landscape tablets, 768px) */
@media (max-width: 768px) {
  .aboutWrapper {
    margin: 0px 5%;
    padding-bottom: 5%;
  }

  .inner {
    flex-direction: column;
  }

  .imageWrapper img {
    padding-top: 5%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and smaller) */
@media only screen and (max-width: 600px) {
  .aboutWrapper {
    padding-bottom: 0px;
  }
  .aboutMeInfo {
    font-size: var(--fz-md);
  }
  .aboutMeHead {
    font-size: var(--fz-xxl);
  }
  .imageWrapper {
    width: 70%;
  }
}

/* Small devices rotated horizontally */
@media only screen and (max-height:500px) and (orientation: landscape) {
  .aboutWrapper {
    padding-bottom: 0px;
  }
  .aboutMeHead {
    font-size: var(--fz-xxl);
  }
  .aboutMeInfo p {
    font-size: var(--fz-md);
  }
  .skillsList {
    font-size: var(--fz-md);
  }
  .imageWrapper {
    width: 50%;
  }
}