.titleContainer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0px;
}

.title {
  transition: transform 4s ease-out;
}

.title h1 {
  margin: 0px 0px 30px 4px;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  font-weight: 400;
}

.title h2 {
  letter-spacing: 0.02em;
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
  color: var(--lightest-slate);
}

.title h3 {
  font-size: clamp(40px, 8vw, 65px);
}

.title p {
  margin-top: 1.2em;
  max-width: 680px;
}

/* Extra extra large devices (large laptops and desktops, until 1366px and lower) */
@media (max-width: 1366px) {
  .title p {
    /* min-width: 900px; */
  }
}

/* Extra large devices (large laptops and desktops, until 1200px and lower) */
@media (max-width: 1200px) {
  .titleContainer {
    /* min-height: none; */
    /* height: 800px; */
  }
}

/* Large devices (laptops/desktops, until 992px and lower) */
@media (max-width: 992px) {
}
/* Medium devices (landscape tablets, until 768px and lower) */
@media (max-width: 768px) {
  .titleContainer {
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .titleContainer {
    height: auto;
    padding-top: var(--nav-height);
  }
  .title h1 {
    margin: 0px 0px 30px 4px;
    font-size: var(--fz-lg);
  }
  .title p {
    font-size: var(--fz-lg);
  }
}

/* Small devices rotated horizontally */
@media only screen and (max-height:500px) and (orientation: landscape) {
  .titleContainer {
    height: auto;
    padding-top: var(--nav-height);
  }
  .title  h1{
    font-size: var(--fz-md);
  }
  .title  h2{
    font-size: var(--fz-xxl);
  }
  .title  h3{
  font-size: var(--fz-xl);
}
.title p {
  font-size: var(--fz-md);
}
}