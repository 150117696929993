.projectWrapper {
  padding: 100px 0;
  max-width: 1100px;
  margin: 0px auto;
}

.projectsHead {
  display: flex;
  margin: 10px 0 40px 0;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
  color: var(--lightest-slate);
  opacity: 0;
}

.projectsHead::before {
  counter-increment: section 3;
  content: '0' counter(section) '.';
  margin-right: 10px;
  color: var(--green);
}

.projectsHead::after {
  content: '';
  display: block;
  position: relative;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: var(--lightest-navy);
}

.styledProjectGrid {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.styledProject {
  margin-bottom: 100px;
  position: relative;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  -webkit-box-align: center;
  align-items: center;
  opacity: 0;
}
.styledProject .projectContent {
  /* opacity: 0; */
  z-index: 2;
}

.styledProject:nth-of-type(2n + 1) .projectContent {
  grid-column: 7/-1;
  text-align: right;
}
.styledProject .projectContent {
  position: relative;
  /* grid-area: 1/1/-1/7; */
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: 7;
}

.projectOverline {
  margin: 10px 0px;
  color: var(--green);
  /* font-family: var(--font-mono); */
  font-size: 1.1rem;
  font-weight: 400;
}

.projectTitle {
  margin: 0px 0px 20px;
}

.projectTitle a {
  text-decoration: none;
  color: var(--light-slate);
}

.projectDescription {
  box-shadow: 0 10px 30px -15px #000;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  z-index: 2;
  padding: 25px;
  border-radius: 4px;
  background-color: var(--light-navy);
  color: #000;
  font-size: 18px;
}

.projectDescription a {
  text-decoration: none;
  color: var(--green);
}

.projectDescription a:hover {
  color: var(--lightest-slate);
  transition: 0.3s ease-in-out;
}

.projectImage a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  z-index: 3;
  transition: var(--transition);

  mix-blend-mode: screen;
}

.subImageWrapper {
  max-width: 700px;
  display: block;
}

.projectDescription p {
  color: var(--light-slate);
}

.styledProject:nth-of-type(2n + 1) .projectTechList {
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.styledProject .projectTechList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  margin: 25px 0px 20px;
  padding: 0px;
  list-style: none;
}

.projectTechList li:nth-child(1),
.projectTechList li:nth-child(2),
.projectTechList li:nth-child(3) {
  margin-right: 15px;
}

.githubLink {
  padding-right: 15px;
}

.feather {
  width: 20px;
  height: 20px;
  fill: none;
  color: #ccc;
}

.styledProject:nth-of-type(2n + 1) .projectImage {
  grid-column: 1/8;
}

.styledProject .projectImage {
  box-shadow: 0 10px 30px -15px #ccc;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  grid-area: 1 / 6 / -1 / -1;
  position: relative;
  z-index: 1;
  /* opacity: 0; */
}

.imageWrapper {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  /* flex-shrink: 0; */
  width: 700px;
}

.imageWrapper img {
  height: auto;
  width: 100%;
  object-fit: cover;
  /* opacity: 0; */
}

@media only screen and (max-width: 600px) {
  .projectsHead {
    font-size: var(--fz-xxl);
  }
  .styledProject .projectContent {
    grid-area: 1/1/-1/-1;
    background-color: var(--green-tint);
    /* opacity: 0.8; */
  }
  .styledProject:nth-of-type(2n + 1) .projectContent {
    grid-area: 1/1/-1/-1;
    background-color: var(--green-tint);
  }

  .projectOverline {
    opacity: 1;
  }
  .projectTitle {
    opacity: 1;
  }
  .projectDescription {
    box-shadow: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: 4px;
    background-color: transparent;
    color: #000;
    font-size: 18px;
    /* opacity: 0.9; */
  }
  .projectDescriptionParagraph {
    opacity: 1;
  }
  .styledProject .projectImage {
    grid-column: 1/-1;
  }

  .styledProject:nth-of-type(2n + 1) .projectImage {
    grid-column: 1/-1;
  }

  .styledProject .projectImage a {
    position: relative;
    height: 100%;
  }
  .imageWrapper {
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(20%);
    /* background-color: #000; */
  }
}
