.jobsWrapper {
  padding: 100px 0;
  max-width: 900px;
  margin: 0px auto;
}

.jobHead {
  display: flex;
  margin: 10px 0 40px 0;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
  color: var(--lightest-slate);
  opacity: 0;
}

.jobHead::before {
  counter-increment: section 2;
  content: '0' counter(section) '.';
  margin-right: 10px;
  color: var(--green);
}

.jobHead::after {
  content: '';
  display: block;
  position: relative;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: var(--lightest-navy);
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: 340px;
  opacity: 0;
}

.tabList {
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.tabListButton {
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  transition: var(--transition);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0px 20px 2px;
  border-left: 2px solid var(--lightest-navy);
  background-color: transparent;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;
  border-right: 0px;
  border-bottom: 0px;
  border-top: 0px;

  border-radius: 0px;
}

.tabList button:hover {
  background-color: var(--light-navy);
  transition: 0.3s ease-in-out;
}

.tabList button:hover span {
  color: var(--green);
  transition: 0.3s ease-in-out;
}

.tabHighlightBar {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  border-radius: var(--border-radius);
  background: var(--green);
}

.tabHighlight {
  /* position: relative; */
  /* height: 168px; */
  /* background-color: var(--lightest-navy); */
  /* width: 2px; */
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  border-radius: var(--border-radius);
  background: var(--green);
  transform: translateY(calc(2 * var(--tab-height)));
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.jobPanel {
  position: relative;
  width: 100%;
  margin-left: 20px;
}

.jobPanel a {
  color: var(--green);
  text-decoration: none;
}

.jobPanel a:hover {
  color: var(--lightest-slate);
  transition: 0.3s ease-in-out;
}

.tabPanel {
  width: 100%;
  height: auto;
  padding: 10px 5px;
  min-height: 300px;
}

.tabPanel h3 {
  margin-bottom: 2px;
  font-size: 22px;
  line-height: 1.3;
}

.jobPanel span {
  color: var(--lightest-slate);
}

.range {
  margin-bottom: 25px;
  color: var(--light-slate);
}

.jobList {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.jobList li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.jobList ::before {
  content: '▹';
  position: absolute;
  left: 0px;
  color: var(--green);
}

.hide {
  display: none;
}

@media (min-width: 769px) {
  .inner {
    min-height: 340px;
    display: flex;
  }

  .tabHighlightBar {
    width: 100%;
    height: 2px;
  }
}

@media (max-width: 768px) {
  .jobsWrapper {
    /* margin: 0px 5%; */
    padding-bottom: 5%;
  }
  .jobHead {
    font-size: var(--fz-xxl);
  }
  .inner {
    display: block;
  }
  .tabList {
    display: flex;
    overflow-x: auto;
    margin-bottom: 30px;
  }
  .tabList button {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-width: 173px;
    padding: 0px 15px;
    border-left: 0px;
    border-bottom: 2px solid var(--lightest-navy);
    text-align: center;
  }
  .tabHighlight {
    top: auto;
    bottom: 0px;
    width: 100%;
    max-width: var(--tab-width);
    height: 2px;

    transform: translateX(calc(2 * var(--tab-width)));
  }
  .jobPanel h3 {
    font-size: var(--fz-lg);
  }
  .range {
    font-size: var(--fz-lg);
  }
  .jobList {
    font-size: var(--fz-md);
  }
  .tabList {
    width: calc(100% + 50px);
    padding-left: 25px;
    margin-left: -25px;
  }
  .tabHighlight {
    margin-left: 25px;
  }
}

@media (max-width: 480px) {
  .tabList {
    width: calc(100% + 50px);
    padding-left: 25px;
    margin-left: -25px;
  }
  .tabHighlight {
    margin-left: 25px;
  }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  .jobHead {
    font-size: var(--fz-xxl);
  }
  .inner {
    display: block;
  }
  .tabList {
    display: flex;
    overflow-x: auto;
    margin-bottom: 30px;
  }
  .tabList button {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-width: 173px;
    padding: 0px 15px;
    border-left: 0px;
    border-bottom: 2px solid var(--lightest-navy);
    text-align: center;
  }
  .tabHighlight {
    top: auto;
    bottom: 0px;
    width: 100%;
    max-width: var(--tab-width);
    height: 2px;

    transform: translateX(calc(2 * var(--tab-width)));
  }
  .jobPanel h3 {
    font-size: var(--fz-lg);
  }
  .range {
    font-size: var(--fz-lg);
  }
  .jobList {
    font-size: var(--fz-md);
  }
  .tabList {
    width: calc(100% + 50px);
    padding-left: 25px;
    margin-left: -25px;
  }
  .tabHighlight {
    margin-left: 25px;
  }
}
